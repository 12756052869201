import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Select,
    MenuItem,
    FormHelperText,
    TableHead,
    Typography
} from '@mui/material';
import { SubmitCampaignInputs } from '../schema';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import Head from './Head';

export const VariableTypeMap = {
    Custom: 'campaigns.submit.placeholders.types.custom',
    Name: 'campaigns.submit.placeholders.types.name',
    PhoneNumber: 'campaigns.submit.placeholders.types.phone_number'
};

const Placeholders = () => {
    const { t } = useTranslation();
    const {
        register,
        control,
        watch,
        formState: { errors }
    } = useFormContext<SubmitCampaignInputs>();

    const templateVariables = useFieldArray({
        name: 'templateVariables',
        control
    }).fields;

    const watched = watch(nameOf<SubmitCampaignInputs>('templateVariables')) as {
        index: number;
        variableType: string; // 'Custom' | 'Name' | 'PhoneNumber' | '';
        fallbackValue: string;
        componentType: string; // 'Body' | 'Header';
    }[];

    return (
        <>
            {templateVariables?.length > 0 && (
                <>
                    <Table size="small" sx={{ mt: 2 }}>
                        <TableHead>
                            <Head />
                        </TableHead>
                        <TableBody>
                            {templateVariables.map((x, i) => {
                                const typeError = Array.isArray(errors.templateVariables)
                                    ? errors.templateVariables[i]?.variableType?.message
                                    : '';

                                const error = Array.isArray(errors.templateVariables)
                                    ? errors.templateVariables[i]?.fallbackValue?.message
                                    : '';

                                const selectedType = watched.find(
                                    (a) => a.index == x.index && a.componentType == x.componentType
                                ).variableType;

                                debugger;
                                return (
                                    <TableRow hover key={x.id}>
                                        <TableCell width={'15%'} align="justify" padding="normal">
                                            <Typography variant="caption" sx={{ fontSize: 'smaller' }}>
                                                {`{\{${x.index}\}}`}{' '}
                                                {x.componentType == 'Body'
                                                    ? t('campaigns.submit.placeholders.list.body')
                                                    : t('campaigns.submit.placeholders.list.header')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell width={'35%'}>
                                            <Select
                                                defaultValue={selectedType}
                                                fullWidth
                                                size="small"
                                                name={`templateVariables[${i}]variableType`}
                                                {...register(`templateVariables.${i}.variableType`)}
                                                error={typeError ? true : false}
                                            >
                                                <MenuItem value={undefined}>
                                                    <em>{t('common.drop_downs.select')}</em>
                                                </MenuItem>
                                                <MenuItem value={'Custom'}>{t(VariableTypeMap['Custom'])}</MenuItem>
                                                <MenuItem value={'Name'}>{t(VariableTypeMap['Name'])}</MenuItem>
                                                <MenuItem value={'PhoneNumber'}>
                                                    {t(VariableTypeMap['PhoneNumber'])}
                                                </MenuItem>
                                            </Select>
                                            <FormHelperText style={{ color: 'red' }}>{typeError}</FormHelperText>
                                        </TableCell>
                                        <TableCell width={'50%'} align="justify" padding="normal">
                                            {selectedType == 'Custom' && (
                                                <TextField
                                                    fullWidth
                                                    name={`templateVariables[${i}]fallbackValue`}
                                                    error={error ? true : false}
                                                    {...register(`templateVariables.${i}.fallbackValue`)}
                                                    placeholder={t('campaigns.submit.placeholders.list.fallback_value')}
                                                    size="small"
                                                    helperText={error}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </>
            )}
        </>
    );
};

export default Placeholders;
