import { OutlinedInput, InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import { SubmitCampaignInputs } from './../schema';
import { nameOf } from 'src/utils/utils.shared';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Template, getTemplateVariables } from 'src/types';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

export default function CampaignTemplate({ templates }: { templates: Template[] }) {
    const { t } = useTranslation();
    const {
        register,
        watch,
        setValue,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitCampaignInputs>();

    const language = watch(nameOf<SubmitCampaignInputs>('languageCode')) as string;
    const templateId = watch(nameOf<SubmitCampaignInputs>('templateId')) as string;
    const template = templates.find((x) => x.id == templateId);

    const isFirst = useRef(true);

    useEffect(() => {
        if (!isFirst.current) {
            if (template) {
                const variables = getTemplateVariables(template);
                setValue(nameOf<SubmitCampaignInputs>('templateVariables'), [...variables], {
                    shouldTouch: true
                });
            }
        }
    }, [template]);

    useEffect(() => {
        isFirst.current = false;
    }, []);

    console.log(defaultValues);
    return (
        <FormControl fullWidth sx={{ maxWidth: '80%', m: 2 }}>
            <InputLabel>{t('campaigns.list.head.template')}</InputLabel>
            <Select
                defaultValue={defaultValues?.templateId}
                disabled={!language}
                error={errors?.templateId && true}
                {...register(nameOf<SubmitCampaignInputs>('templateId'))}
                id={nameOf<SubmitCampaignInputs>('templateId')}
                size="medium"
                input={<OutlinedInput label={t('campaigns.list.head.template')} />}
            >
                {_.chain(templates)
                    .filter((x) => x.language == language)
                    .map((x) => ({
                        name: x.name,
                        id: x.id
                    }))
                    .unshift({
                        id: undefined,
                        name: undefined
                    })
                    .value()
                    .map((x) =>
                        x == undefined ? (
                            <MenuItem value={x.name} key={-1}>
                                <em>{t('common.drop_downs.select')}</em>
                            </MenuItem>
                        ) : (
                            <MenuItem key={x.id} value={x.id}>
                                {x.name}
                            </MenuItem>
                        )
                    )}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors?.templateId?.message}</FormHelperText>
        </FormControl>
    );
}
