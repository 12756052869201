import {
    ApiListResponse,
    ApiResponse,
    ChatNote,
    MessageType,
    Conversation,
    QuickReply,
    RecentContact,
    User,
    TemplateVariable,
    TemplateActionResponse,
    AssignChatInputs
} from 'src/types';
import apiClient from './apiClient';
import { QuickReplyInputs } from 'src/screens/conversations/content/quickReplies/submit/schema';
import { NoteInputs } from 'src/screens/conversations/content/notes/submit/schema';
import { NewContactResponse } from 'src/types/types.contact';
const key: string = 'favourite';

export class ConversationsService {
    async getRecentContacts(
        messageType: MessageType,
        page: number,
        limit: number,
        getBotConversations: boolean,
        getMyConversations: boolean,
        getBlockedContacts: boolean,
        searchTerm?: string,
        teamId?: string
    ) {
        const { data } = await apiClient.get<ApiListResponse<RecentContact>>('/contacts/recent-contacts', {
            params: {
                messageType,
                teamId,
                getBotConversations,
                getMyConversations,
                getBlockedContacts,
                searchTerm,
                page,
                limit
            }
        });
        return data;
    }

    async getConversations(page: number, limit: number, contactId: string) {
        const { data } = await apiClient.get<ApiListResponse<Conversation>>(`/contacts/${contactId}/conversations`, {
            params: {
                contactId,
                page,
                limit
            }
        });
        return data;
    }

    async sendMessage({
        message,
        contactId,
        correlationId,
        impersonatedAgentId,
        url,
        type
    }: {
        message: string;
        contactId: string;
        correlationId: number;
        impersonatedAgentId: string;
        url: string;
        type: string;
    }) {
        const { data } = await apiClient.post<ApiResponse<Conversation>>(`/contacts/${contactId}/conversations`, {
            contactId,
            message,
            correlationId,
            impersonatedAgentId,
            mediaUrl: url,
            mediaType: type
        });
        return data;
    }

    async sendTemplateMessage({
        templateId,
        contactId,
        correlationId,
        impersonatedAgentId,
        templateVariables,
        contactTemplateResponses
    }: {
        templateId: string;
        contactId: string;
        correlationId: number;
        impersonatedAgentId: string;
        templateVariables: TemplateVariable[];
        contactTemplateResponses: { buttonText: string; response: string }[];
    }) {
        const { data } = await apiClient.post<ApiResponse<Conversation>>(
            `/contacts/${contactId}/TemplateConversations`,
            {
                contactId,
                templateId,
                correlationId,
                impersonatedAgentId,
                templateVariableValues: templateVariables.map((x) => ({
                    ...x,
                    value: x.fallbackValue
                })),
                contactTemplateResponses
            }
        );
        return data;
    }

    async markMessagesAsread(contactId: string) {
        const { data } = await apiClient.put<ApiListResponse<Conversation>>(`/contacts/${contactId}/MarkAsRead`, {
            contactId
        });
        return data;
    }

    async getQuickReplies(page: number, limit: number): Promise<ApiListResponse<QuickReply>> {
        const { data } = await apiClient.get<ApiListResponse<QuickReply>>('/quick-replies', {
            params: {
                page,
                limit: 20
            }
        });
        return data;
    }

    async addQuickreply(inputs: QuickReplyInputs) {
        const { data } = await apiClient.post<ApiResponse<string>>('/quick-replies', inputs);
        return data;
    }

    async updateQuickreply(inputs: QuickReplyInputs) {
        const { data } = await apiClient.put<ApiResponse<string>>(`/quick-replies/${inputs.id}`, inputs);
        return data;
    }

    async deleteQuickreply(id: string) {
        const { data } = await apiClient.delete<ApiResponse<string>>(`/quick-replies/${id}`);
        return data;
    }

    getFavourite(): QuickReply {
        return JSON.parse(localStorage.getItem(key));
    }

    addFavourite(quickreply: QuickReply) {
        localStorage.setItem(key, JSON.stringify(quickreply));
    }

    removeFavourite() {
        localStorage.removeItem(key);
    }

    async getNotes(page: number, limit: number, contactId: string): Promise<ApiListResponse<ChatNote>> {
        const { data } = await apiClient.get<ApiListResponse<ChatNote>>(`/contacts/${contactId}/Chat-Notes`, {
            params: {
                page,
                limit
            }
        });

        return data;
    }

    async addNote(inputs: NoteInputs) {
        const { data } = await apiClient.post<ApiResponse<string>>(`/contacts/${inputs.contactId}/Chat-Notes`, inputs);
        return data;
    }

    async getUsers(page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<User>>('/users', {
            params: {
                page,
                limit
            }
        });
        return data;
    }

    async blockContact(contactId: string) {
        const { data } = await apiClient.put<ApiResponse<string>>(`/contacts/${contactId}/block`);
        return data;
    }

    async closeChat(contactId: string) {
        const { data } = await apiClient.put<ApiResponse<string>>(`/contacts/${contactId}/endsession`);
        return data;
    }

    async uploadFile(file: File) {
        const { data } = await apiClient.post<ApiResponse<string>>(`/Media`, {
            file
        });
        return data;
    }

    async addNewContact(phoneNumber: string) {
        const { data } = await apiClient.put<ApiResponse<NewContactResponse>>('/contacts', {
            phoneNumber
        });
        return data;
    }

    async getTemplateActionResponses(contactId: string) {
        const { data } = await apiClient.get<ApiResponse<TemplateActionResponse[]>>(
            `/contacts/${contactId}/templateActionResponses`
        );
        return data;
    }

    async assignChat(payload: AssignChatInputs) {
        const { data } = await apiClient.post<ApiResponse<string>>(`/chatAssignments`, payload);
        return data;
    }
}

export default new ConversationsService();
