import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { SnackbarUtils } from 'src/components';
import { ConversationsService } from 'src/services/conversation.service';
import { RootState } from 'src/store';
import {
    ChatNote,
    ChatRequest,
    Conversation,
    MediaType,
    QuickReply,
    RecentContact,
    Template,
    TemplateActionResponse,
    TemplateVariable,
    AssignChatInputs,
    User
} from 'src/types';
import { ActionState, MessageType, DefaultTableData, TableData } from 'src/types/types.shared';
import { QuickReplyInputs } from './content/quickReplies/submit/schema';
import { NoteInputs } from './content/notes/submit/schema';
import _ from 'lodash';
import { getMediaUrl, deleteMediaByFilename } from 'src/utils/utils.shared';

const service = new ConversationsService();

export interface SliceState {
    chatRequests: ChatRequest[];
    contacts: {
        state: ActionState;
        list: TableData<RecentContact>;
        messageType: MessageType;
    };
    notes: {
        state: ActionState;
        list: TableData<ChatNote>;
    };
    quickReplies: {
        submitState: ActionState;
        state: ActionState;
        list: TableData<QuickReply>;
    };
    favourites: {
        state: ActionState;
        current: QuickReply;
    };
    conversations: {
        scrollToBottom: boolean;
        contactId: string;
        markedAsReadOnClient: Date;
        state: ActionState;
        list: TableData<Conversation>;
    };
    addContactOnFly: {
        state: ActionState;
        contactId: string;
    };
    users: {
        state: ActionState;
        list: TableData<User>;
        impersonatedUser: {
            name: string;
            id: string;
        };
    };
    media: {
        state: ActionState;
        url: undefined | string;
        name: string;
        type: MediaType;
        file: any;
    };
    templateActionResponses: (TemplateActionResponse & {
        contactId: string;
    })[];
}

const initialState: SliceState = {
    chatRequests: [],
    contacts: {
        list: DefaultTableData(),
        state: undefined,
        messageType: 'open'
    },
    notes: {
        list: DefaultTableData(),
        state: undefined
    },
    conversations: {
        scrollToBottom: true,
        contactId: '',
        markedAsReadOnClient: undefined,
        list: DefaultTableData(),
        state: undefined
    },
    addContactOnFly: {
        state: undefined,
        contactId: undefined
    },
    favourites: {
        state: undefined,
        current: null
    },
    quickReplies: {
        list: DefaultTableData(),
        state: undefined,
        submitState: undefined
    },
    users: {
        state: undefined,
        list: DefaultTableData(),
        impersonatedUser: {
            name: undefined,
            id: undefined
        }
    },
    media: {
        state: undefined,
        url: undefined,
        type: undefined,
        file: undefined,
        name: undefined
    },
    templateActionResponses: []
};

export const getRecentContacts = createAsyncThunk(
    'GET_RECENT_CONTACTS',
    async (
        { contactFilter, nextPage, searchTerm }: { contactFilter: string; nextPage: boolean; searchTerm?: string },
        thunkAPI
    ) => {
        let getBotConversations = false;
        let getMyConversations = false;
        let getBlockedContacts = false;
        switch (contactFilter) {
            case 'blocked-contacts':
                getBlockedContacts = true;
                break;
            case 'my-conversations':
                getMyConversations = true;
                break;
            case 'bot-conversations':
                getBotConversations = true;
                break;
        }
        const teamId = getBotConversations || getMyConversations || getBlockedContacts ? null : contactFilter;
        const state = thunkAPI.getState() as RootState;
        const { list, messageType: chatType } = state.conversations.contacts;

        const response = await service.getRecentContacts(
            chatType,
            nextPage ? list.page + 1 : list.page,
            10,
            getBotConversations,
            getMyConversations,
            getBlockedContacts,
            searchTerm,
            teamId
        );
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const markMessagesAsread = createAsyncThunk('MARK_MESSAGES_AS_READ', async (contactId: string, thunkAPI) => {
    const response = await service.markMessagesAsread(contactId);
    thunkAPI.dispatch(resetUnreadMessage(contactId));
    if (response.status === 'Successful')
        return {
            contactId
        };
    return thunkAPI.rejectWithValue(response.message);
});

export const getConversations = createAsyncThunk(
    'GET_CONVERSATIONS',
    async ({ contactId, nextPage }: { contactId: string; nextPage: boolean }, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const { page, limit } = state.conversations.conversations.list;
        const response = await service.getConversations(nextPage ? page + 1 : 1, nextPage ? limit : 20, contactId);

        if (response.status === 'Successful')
            return {
                ...response.data,
                contactId
            };
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const sendMessage = createAsyncThunk(
    'SEND_MESSAGE',
    async ({ message, contactId }: { message: string; contactId: string }, thunkAPI) => {
        const min = Math.pow(10, 9);
        const max = Math.pow(10, 10) - 1;
        var correlationId = Math.floor(Math.random() * (max - min + 1) + min);

        const state = thunkAPI.getState() as RootState;
        const { id, firstName, lastName } = state.account.user;
        const { url, type } = state.conversations.media;
        const { id: impersonatedAgentId, name: impersonatedAgentName } = state.conversations.users.impersonatedUser;
        const response = await service.sendMessage({
            message,
            contactId,
            correlationId,
            impersonatedAgentId,
            url,
            type
        });
        if (response.status === 'Successful') {
            return {
                message,
                contactId,
                agentId: id,
                firstName,
                lastName,
                correlationId,
                impersonatedAgentId,
                impersonatedAgentName,
                url,
                type
            };
        }
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const sendTemplateMessage = createAsyncThunk(
    'SEND_TEMPLATE_MESSAGE',
    async (
        {
            template,
            contactId,
            templateVariables,
            contactTemplateResponses
        }: {
            template: Template;
            contactId: string;
            templateVariables: TemplateVariable[];
            contactTemplateResponses: { buttonText: string; response: string }[];
        },
        thunkAPI
    ) => {
        const min = Math.pow(10, 9);
        const max = Math.pow(10, 10) - 1;
        var correlationId = Math.floor(Math.random() * (max - min + 1) + min);

        const state = thunkAPI.getState() as RootState;
        const { id, firstName, lastName } = state.account.user;
        const { id: impersonatedAgentId, name: impersonatedAgentName } = state.conversations.users.impersonatedUser;
        const response = await service.sendTemplateMessage({
            templateId: template.id,
            contactId,
            correlationId,
            impersonatedAgentId,
            templateVariables,
            contactTemplateResponses
        });
        if (response.status === 'Successful') {
            return {
                template,
                contactId,
                agentId: id,
                firstName,
                lastName,
                correlationId,
                impersonatedAgentId,
                impersonatedAgentName
            };
        }
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const getQuickreplies = createAsyncThunk('GET_QUICK_REPLIES', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.conversations.quickReplies.list;
    const response = await service.getQuickReplies(page, limit);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addQuickreply = createAsyncThunk('ADD_QUICK_REPLY', async (inputs: QuickReplyInputs, thunkAPI) => {
    const response = await service.addQuickreply(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateQuickreply = createAsyncThunk('UPDATE_QUICK_REPLY', async (inputs: QuickReplyInputs, thunkAPI) => {
    const response = await service.updateQuickreply(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const deleteQuickreply = createAsyncThunk('DELETE_QUICK_REPLY', async (id: string, thunkAPI) => {
    const response = await service.deleteQuickreply(id);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getFavourites = createAsyncThunk('GET_FAVOURITES', () => {
    const response = service.getFavourite();
    return response;
});

export const addFavourite = createAsyncThunk('ADD_FAVOURITE', (quickReply: QuickReply) => {
    service.addFavourite(quickReply);
    return quickReply;
});

export const removeFavourite = createAsyncThunk('REMOVE_FAVOURITE', () => {
    service.removeFavourite();
});

export const getNotes = createAsyncThunk('GET_NOTES', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.conversations.notes.list;
    const contactId = state.conversations.conversations.contactId;
    const response = await service.getNotes(page, limit, contactId);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addNote = createAsyncThunk('ADD_NOTE', async (inputs: NoteInputs, thunkAPI) => {
    const response = await service.addNote(inputs);
    if (response.status === 'Successful') return inputs;
    return thunkAPI.rejectWithValue(response.message);
});

export const getUsers = createAsyncThunk('GET_USERS_LIST', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.conversations.users.list;
    const response = await service.getUsers(page, limit);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const blockContact = createAsyncThunk(
    'BLOCK_CONTACT',
    async ({ contactId }: { contactId: string }, thunkAPI) => {
        const response = await service.blockContact(contactId);
        if (response.status === 'Successful') {
            return contactId;
        }
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const closeChat = createAsyncThunk('CLOSE_CHAT', async ({ contactId }: { contactId: string }, thunkAPI) => {
    const response = await service.closeChat(contactId);
    if (response.status === 'Successful') {
        return contactId;
    }
    return thunkAPI.rejectWithValue(response.message);
});

export const uploadMedia = createAsyncThunk(
    'UPLOAD_MEDIA',
    async ({ link, name, type, file }: { link: string; name: string; type: MediaType; file: any }, thunkAPI) => {
        const response = await getMediaUrl({ link, name });
        if (response.status === 'Successful') {
            return { url: response.data.url, link, name, type, file };
        }
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const deleteMedia = createAsyncThunk('DELETE_MEDIA', async (fileId: string, thunkAPI) => {
    const response = await deleteMediaByFilename(fileId);
    if (response.status === 'Successful') {
        return response.data;
    }
    return thunkAPI.rejectWithValue(response.message);
});

export const addNewContact = createAsyncThunk('ADD_NEW_CONTACT', async (phoneNumber: string, thunkAPI) => {
    const response = await service.addNewContact(phoneNumber);
    if (response.status === 'Successful') {
        return response.data;
    }
    return thunkAPI.rejectWithValue(response.message);
});

export const getTemplateActionResponses = createAsyncThunk(
    'GET_TEMPLATE_ACTION_RESPONSES',
    async (contactId: string, thunkAPI) => {
        const response = await service.getTemplateActionResponses(contactId);
        if (response.status === 'Successful')
            return response.data.map((x) => ({
                ...x,
                contactId
            }));
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const assignChat = createAsyncThunk('ASSIGN_CHAT', async (inputs: AssignChatInputs, thunkApi) => {
    const response = await service.assignChat(inputs);
    if (response.status == 'Successful') {
        return response.data;
    }

    return thunkApi.rejectWithValue(response.message);
});

const slice = createSlice({
    name: 'conversations',
    initialState,
    reducers: {
        chatRequestRecieved: (state, action: PayloadAction<ChatRequest>) => {
            const payload = action.payload;
            state.chatRequests = _.reject(state.chatRequests, (x) => x.id == payload.id && x.teamId == payload.teamId);
            state.chatRequests.unshift(payload);

            // var contact = state.contacts.list.items.find((x) => x.id == payload.id);
            // if (contact) {
            //     state.conversations = {
            //         scrollToBottom: true,
            //         contactId: '',
            //         list: DefaultTableData(),
            //         state: undefined
            //     };
            //     state.contacts.list.items = state.contacts.list.items.filter((x) => x.id != contact.id);
            // }

            // const payload = action.payload;
            // if (window.location.href.indexOf('conversations') == -1) {
            //     state.chatRequests = state.chatRequests.filter((x) => x.contactId != payload.contactId);
            //     state.chatRequests.unshift(payload);
            // }
            // var contact = state.contacts.list.items.find((x) => x.id == payload.contactId);
            // if (contact) {
            //     state.contacts.list.items = state.contacts.list.items.filter((x) => x.id != contact.id);
            // }
            // state.contacts.list.items.unshift({
            //     id: payload.contactId,
            //     name: payload.contactName,
            //     phoneNumber: payload.phoneNumber,
            //     unread: contact?.unread ? contact.unread + 1 : 1,
            //     color: contact.color ?? '#ccc',
            //     hasNotes: contact.hasNotes,
            //     isBlocked: contact.isBlocked
            // });
        },
        conversationArrived: (state, action: PayloadAction<Conversation>) => {
            const payload = action.payload;
            // if (window.location.href.indexOf('conversations') == -1) {
            //     state.chatRequests = state.chatRequests.filter((x) => x.contactId != payload.contactId);
            //     state.chatRequests.unshift({
            //         contactId: payload.contactId,
            //         contactName: payload.contactName,
            //         createdOn: payload.createdOn,
            //         message: payload.incomingMessage.text,
            //         phoneNumber: payload.phoneNumber,
            //         read: false
            //     });
            // }
            if (state.conversations.contactId == payload.contactId) {
                state.conversations.markedAsReadOnClient = new Date();
                state.conversations.list.items.push(payload);
            } else {
                var contact = state.contacts.list.items.find((x) => x.id == payload.contactId);
                if (contact) {
                    state.contacts.list.items = state.contacts.list.items.map((x) => {
                        return x.id == contact.id ? { ...x, unread: x.unread + 1 } : x;
                    });
                } else {
                    // state.contacts.list.items.unshift({
                    //     id: payload.contactId,
                    //     name: payload.contactName,
                    //     color: '',
                    //     hasNotes: false,
                    //     isBlocked: false,
                    //     phoneNumber: payload.phoneNumber,
                    //     unread: 1
                    // });
                }
            }

            // const contactId = state.conversations.list.items.find(
            //     (x) => x.correlationId == action.payload.correlationId
            // )?.contactId;

            // if (contactId && state.conversations.contactId != contactId) {
            //     state.contacts.list.items = state.contacts.list.items.map((x) => {
            //         return x.id == contactId ? { ...x, unread: x.unread + 1 } : x;
            //     });
            // }
        },
        resetConversations: (state) => {
            state.conversations = {
                scrollToBottom: true,
                markedAsReadOnClient: undefined,
                contactId: '',
                list: DefaultTableData(),
                state: undefined
            };
        },
        resetContacts: (state) => {
            state.contacts = {
                list: DefaultTableData(),
                state: undefined,
                messageType: 'open'
            };
        },
        setMessageType: (state, action: PayloadAction<MessageType>) => {
            state.contacts = {
                list: DefaultTableData(),
                state: 'inProgress',
                messageType: action.payload
            };
        },
        markChatRequestsAsRead: (state) => {
            state.chatRequests = state.chatRequests.map((x) => ({
                ...x,
                read: true
            }));
        },
        markMessageForwarded: (state, action: PayloadAction<{ id: string; correlationId: number }>) => {
            state.conversations.list.items = state.conversations.list.items.map((x) => {
                return x.correlationId == action.payload.correlationId
                    ? { ...x, id: action.payload.id, status: 'loading' }
                    : x;
            });
        },
        setQuickRepliesPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.quickReplies.list.page = action.payload.page;
            state.quickReplies.list.limit = action.payload.limit;
        },
        setPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.users.list.page = action.payload.page;
            state.users.list.limit = action.payload.limit;
        },
        resetPaging: (state) => {
            state.users.list.page = 1;
            state.users.list.items = [];
        },
        setImpersonatedUser: (state, action: PayloadAction<{ username: string; impersonatedAgentId: string }>) => {
            state.users.impersonatedUser = {
                name: action.payload.username,
                id: action.payload.impersonatedAgentId
            };
        },
        resetUnreadMessage: (state, action: PayloadAction<string>) => {
            state.contacts.list.items = state.contacts.list.items.map((x) => {
                return x.id == action.payload ? { ...x, unread: 0 } : x;
            });
        }
    },
    extraReducers(builder) {
        builder.addCase(getRecentContacts.rejected, (state, action) => {
            state.contacts.state = 'failed';
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('conversations.slice.errors.fetch_recent_contacts')
            );
        });
        builder.addCase(getRecentContacts.pending, (state) => {
            state.contacts.state = 'inProgress';
        });
        builder.addCase(getRecentContacts.fulfilled, (state, action) => {
            const payload = action.payload;
            payload.items.forEach((x) => {
                if (!state.contacts.list.items.find((y) => y.id == x.id)) {
                    state.contacts.list.items.push(x);
                }
            });
            state.conversations.contactId = '';
            state.contacts.list.page = payload.page;
            state.contacts.list.limit = payload.limit;
            state.contacts.list.totalItems = payload.totalItems;
            state.contacts.state = 'successful';
        });
        builder.addCase(getConversations.pending, (state) => {
            state.conversations.state = 'inProgress';
            state.addContactOnFly.state = undefined;
            state.addContactOnFly.contactId = undefined;
        });
        builder.addCase(getConversations.fulfilled, (state, action) => {
            const payload = action.payload;
            if (state.conversations.contactId == payload.contactId) {
                payload.items.forEach((x) => {
                    if (!state.conversations.list.items.find((c) => c.id == x.id)) {
                        state.conversations.list.items.push(x);
                    }
                });

                state.conversations.list.page = payload.page;
                state.conversations.list.limit = payload.limit;
                state.conversations.list.totalItems = payload.totalItems;
            } else {
                state.conversations.contactId = payload.contactId;
                state.conversations.list = payload;
            }
            if (payload.page > 1) {
                state.conversations.scrollToBottom = false;
            }
            state.conversations.state = 'successful';
        });

        builder.addCase(getConversations.rejected, (state) => {
            state.contacts.state = 'failed';
        });

        builder.addCase(sendMessage.pending, (state) => {
            state.conversations.state = 'inProgress';
        });
        builder.addCase(sendMessage.fulfilled, (state, action) => {
            const payload = action.payload;

            var contact = state.contacts.list.items.find((x) => x.id == payload.contactId);
            if (!contact) {
                var chatRequest = state.chatRequests.find((x) => x.id == payload.contactId);
                state.contacts.list.items.unshift(chatRequest);
            }
            state.chatRequests = state.chatRequests.filter((x) => x.id != payload.contactId);

            state.conversations.list.items.push({
                contactId: payload.contactId,
                contactName: '',
                id: `${state.conversations.list.items.length + 1}`,
                phoneNumber: '',
                outGoingMessage: {
                    agentId: payload.agentId,
                    agentName: payload.impersonatedAgentId
                        ? payload.impersonatedAgentName
                        : `${payload.firstName} ${payload.lastName}`,
                    interfaceType: payload.url ? 'MediaWithCaption' : 'Text',
                    text: payload.message,
                    mediaWithCaption: {
                        caption: payload.message,
                        media: {
                            link: payload.url,
                            type: payload.type
                        }
                    }
                },
                createdOn: '',
                deliveredOn: '',
                readOn: '',
                sentOn: '',
                correlationId: payload.correlationId
            });
            state.conversations.scrollToBottom = true;
            state.conversations.state = 'successful';

            state.media.url = undefined;
            state.media.name = undefined;
            state.media.type = undefined;
            state.media.file = undefined;
        });
        builder.addCase(sendMessage.rejected, (state, action) => {
            state.conversations.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.send_message'));
        });

        builder.addCase(sendTemplateMessage.pending, (state) => {
            state.conversations.state = 'inProgress';
        });
        builder.addCase(sendTemplateMessage.fulfilled, (state, action) => {
            const payload = action.payload;

            var contact = state.contacts.list.items.find((x) => x.id == payload.contactId);
            if (!contact) {
                var chatRequest = state.chatRequests.find((x) => x.id == payload.contactId);
                state.contacts.list.items.unshift(chatRequest);
            }
            state.chatRequests = state.chatRequests.filter((x) => x.id != payload.contactId);

            state.conversations.list.items.push({
                contactId: payload.contactId,
                contactName: '',
                id: `${state.conversations.list.items.length + 1}`,
                phoneNumber: '',
                outGoingMessage: {
                    agentId: payload.agentId,
                    agentName: payload.impersonatedAgentId
                        ? payload.impersonatedAgentName
                        : `${payload.firstName} ${payload.lastName}`,
                    interfaceType: 'Template',
                    template: payload.template,
                    text: ''
                },
                createdOn: '',
                deliveredOn: '',
                readOn: '',
                sentOn: '',
                correlationId: payload.correlationId
            });
            state.conversations.scrollToBottom = true;
            state.conversations.state = 'successful';
        });
        builder.addCase(sendTemplateMessage.rejected, (state, action) => {
            state.conversations.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.send_message'));
        });

        builder.addCase(markMessagesAsread.pending, (state) => {});
        builder.addCase(markMessagesAsread.fulfilled, (state, action) => {
            state.contacts.list.items = state.contacts.list.items.map((x) => {
                return x.id == action.payload.contactId ? { ...x, unread: 0 } : x;
            });

            state.chatRequests = state.chatRequests.map((x) => {
                return x.id == action.payload.contactId ? { ...x, unread: 0 } : x;
            });

            state.contacts.state = 'successful';
        });
        builder.addCase(markMessagesAsread.rejected, (state) => {});

        builder.addCase(getQuickreplies.pending, (state) => {
            state.quickReplies.state = 'inProgress';
            state.quickReplies.submitState = undefined;
        });
        builder.addCase(getQuickreplies.fulfilled, (state, action) => {
            state.quickReplies.list = action.payload;
            state.quickReplies.state = 'successful';
            state.quickReplies.submitState = undefined;
        });
        builder.addCase(getQuickreplies.rejected, (state, action) => {
            state.quickReplies.state = 'failed';
            state.quickReplies.submitState = undefined;
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('conversations.slice.errors.fetch_quick_replies')
            );
        });

        builder.addCase(addQuickreply.pending, (state) => {
            state.quickReplies.submitState = 'inProgress';
            state.quickReplies.state = undefined;
        });
        builder.addCase(addQuickreply.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('conversations.slice.success.add_quick_reply'));
            state.quickReplies.submitState = 'successful';
            state.quickReplies.state = undefined;
        });
        builder.addCase(addQuickreply.rejected, (state, action) => {
            state.quickReplies.submitState = 'failed';
            state.quickReplies.state = undefined;
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.add_quick_reply'));
        });

        builder.addCase(updateQuickreply.pending, (state) => {
            state.quickReplies.submitState = 'inProgress';
            state.quickReplies.state = undefined;
        });
        builder.addCase(updateQuickreply.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('conversations.slice.success.update_quick_reply'));
            state.quickReplies.submitState = 'successful';
            state.quickReplies.state = undefined;
        });
        builder.addCase(updateQuickreply.rejected, (state, action) => {
            state.quickReplies.submitState = 'failed';
            state.quickReplies.state = undefined;
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('conversations.slice.errors.update_quick_reply')
            );
        });

        builder.addCase(deleteQuickreply.pending, (state) => {
            state.quickReplies.submitState = 'inProgress';
            state.quickReplies.state = undefined;
        });
        builder.addCase(deleteQuickreply.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('conversations.slice.success.delete_quick_reply'));
            state.quickReplies.submitState = 'successful';
            state.quickReplies.state = undefined;
        });
        builder.addCase(deleteQuickreply.rejected, (state, action) => {
            state.quickReplies.submitState = 'failed';
            state.quickReplies.state = undefined;
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('conversations.slice.errors.delete_quick_reply')
            );
        });

        builder.addCase(getFavourites.pending, (state) => {
            state.favourites.state = 'inProgress';
        });
        builder.addCase(getFavourites.fulfilled, (state, action) => {
            state.favourites.current = action.payload;
            state.favourites.state = 'successful';
        });
        builder.addCase(getFavourites.rejected, (state, action) => {
            state.favourites.state = 'failed';
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('conversations.slice.errors.fetch_favourites')
            );
        });

        builder.addCase(addFavourite.pending, (state) => {
            state.favourites.state = 'inProgress';
        });
        builder.addCase(addFavourite.fulfilled, (state, action) => {
            SnackbarUtils.success(i18next.t('conversations.slice.success.add_favourite'));
            state.favourites.state = 'successful';
            state.favourites.current = action.payload;
        });
        builder.addCase(addFavourite.rejected, (state, action) => {
            state.favourites.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.add_favourite'));
        });

        builder.addCase(removeFavourite.pending, (state) => {
            state.favourites.state = 'inProgress';
        });
        builder.addCase(removeFavourite.fulfilled, (state, action) => {
            SnackbarUtils.success(i18next.t('conversations.slice.success.remove_favourite'));
            state.favourites.state = 'successful';
            state.favourites.current = null;
        });
        builder.addCase(removeFavourite.rejected, (state, action) => {
            state.favourites.state = 'failed';
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('conversations.slice.errors.remove_favourite')
            );
        });

        builder.addCase(getNotes.pending, (state) => {
            state.notes.state = 'inProgress';
        });
        builder.addCase(getNotes.fulfilled, (state, action) => {
            // action.payload.items.map((x) => state.notes.list.items.push(x));
            state.notes.list.items = action.payload.items;
            state.notes.list.page = action.payload.page;
            state.notes.list.limit = action.payload.limit;
            state.favourites.state = 'successful';
        });
        builder.addCase(getNotes.rejected, (state, action) => {
            state.notes.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.fetch_notes'));
        });

        builder.addCase(addNote.pending, (state) => {
            state.notes.state = 'inProgress';
        });
        builder.addCase(addNote.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('conversations.slice.success.add_note'));
            state.notes.state = 'successful';
        });
        builder.addCase(addNote.rejected, (state, action) => {
            state.notes.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.add_note'));
        });

        builder.addCase(getUsers.pending, (state) => {
            state.users.state = 'inProgress';
        });
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.users.list = {
                ...action.payload,
                items: [...state.users.list.items, ...action.payload.items]
            };
            state.users.state = 'successful';
        });
        builder.addCase(getUsers.rejected, (state, action) => {
            state.users.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.fetch_users'));
        });

        builder.addCase(blockContact.pending, () => {});
        builder.addCase(blockContact.fulfilled, (state, action) => {
            SnackbarUtils.success(i18next.t('conversations.slice.success.block_user'));
            state.contacts.list.items = state.contacts.list.items.map((x) =>
                x.id != action.payload ? x : { ...x, isBlocked: true }
            );
        });
        builder.addCase(blockContact.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.block_user'));
        });

        builder.addCase(closeChat.pending, () => {});
        builder.addCase(closeChat.fulfilled, (state, action) => {
            state.contacts.list.items = state.contacts.list.items.filter((x) => x.id != action.payload);
            SnackbarUtils.success(i18next.t('conversations.slice.success.close_chat'));
        });
        builder.addCase(closeChat.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.close_chat'));
        });

        builder.addCase(uploadMedia.pending, (state) => {
            state.media.state = 'inProgress';
            state.media.url = undefined;
            state.media.name = undefined;
            state.media.type = undefined;
            state.media.file = undefined;
        });
        builder.addCase(uploadMedia.fulfilled, (state, action) => {
            state.media.state = 'successful';
            state.media.url = action.payload.url;
            state.media.name = action.payload.name;
            state.media.type = action.payload.type;
            state.media.file = action.payload.file;
        });
        builder.addCase(uploadMedia.rejected, (state, action) => {
            state.media.state = 'failed';
            state.media.url = undefined;
            state.media.name = undefined;
            state.media.type = undefined;
            state.media.file = undefined;
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.media_upload'));
        });

        builder.addCase(deleteMedia.pending, (state) => {
            state.media.state = 'inProgress';
        });
        builder.addCase(deleteMedia.fulfilled, (state) => {
            state.media.state = 'successful';
            state.media.url = undefined;
        });
        builder.addCase(deleteMedia.rejected, (state, action) => {
            state.media.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.media_delete'));
        });

        builder.addCase(addNewContact.pending, (state) => {
            state.conversations.state = 'inProgress';
            state.addContactOnFly.state = 'inProgress';
        });

        builder.addCase(addNewContact.fulfilled, (state, action) => {
            SnackbarUtils.success(i18next.t('conversations.slice.success.add_new_contact'));
            const contact = {
                id: action.payload.id,
                name: action.payload.name != null ? action.payload.name : action.payload.phoneNumber,
                phoneNumber: action.payload.phoneNumber,
                unread: 0,
                color: action.payload.color,
                hasNotes: false,
                isBlocked: false,
                feedback: action.payload.feedback
            };
            state.conversations.state = 'successful';
            state.addContactOnFly.state = 'successful';
            state.addContactOnFly.contactId = action.payload.id;
            if (state.contacts.list.items.length === 0) state.contacts.list.items.push(contact);
            if (state.contacts.list.items[0].id == action.payload.id) return;
            state.contacts.list.items = state.contacts.list.items.filter((x) => x.id != action.payload.id);
            state.contacts.list.items.unshift(contact);
            state.conversations.contactId = action.payload.id;
            state.conversations.list.page = 1;
            state.conversations.list.items = [];
        });

        builder.addCase(addNewContact.rejected, (state, action) => {
            state.conversations.state = 'failed';
            state.addContactOnFly.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.add_new_contact'));
        });

        builder.addCase(getTemplateActionResponses.fulfilled, (state, action) => {
            action.payload.forEach((r) => {
                let resp = state.templateActionResponses.find(
                    (x) => x.contactId == r.contactId && x.templateId == r.templateId && x.buttonText == r.buttonText
                );
                if (resp) {
                    resp.responseText = r.responseText;
                } else {
                    state.templateActionResponses.push(r);
                }
            });
        });

        builder.addCase(assignChat.pending, (state) => {
            state.conversations.state = 'inProgress';
        });

        builder.addCase(assignChat.fulfilled, (state, action) => {
            state.conversations.state = 'successful';
            SnackbarUtils.success(action?.payload?.toString() ?? i18next.t('conversations.slice.success.chat_assign'));
        });

        builder.addCase(assignChat.rejected, (state, action) => {
            state.conversations.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('conversations.slice.errors.chat_assign'));
        });
    }
});

export const {
    markMessageForwarded,
    chatRequestRecieved,
    conversationArrived,
    resetConversations,
    markChatRequestsAsRead,
    resetContacts,
    setQuickRepliesPaging,
    setPaging,
    resetPaging,
    setImpersonatedUser,
    setMessageType,
    resetUnreadMessage
} = slice.actions;

export default slice.reducer;
