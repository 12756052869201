import { ColumnLabel, XTableHead } from 'src/components';

function Head() {
    const columns: ColumnLabel[] = [
        {
            align: 'left',
            labelKey: 'campaigns.list.head.name'
        },
        {
            align: 'left',
            labelKey: 'campaigns.list.head.template'
        },
        {
            align: 'left',
            labelKey: 'campaigns.list.head.language_code'
        },
        {
            align: 'center',
            labelKey: 'campaigns.list.head.status'
        },
        {
            align: 'center',
            labelKey: 'campaigns.list.head.reports'
        },
        {
            align: 'center',
            labelKey: 'campaigns.list.head.view'
        },
        {
            align: 'right',
            labelKey: 'common.lists.actions'
        }
    ];
    return <XTableHead columns={columns} />;
}

export default Head;
