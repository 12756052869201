import { XSidebarListItem } from 'src/components';
import _ from 'lodash';
import { CampaignReport } from 'src/types';
import { format } from 'date-fns';
import { DATE_DISPLAY_FORMAT } from 'src/utils/constants';

const Sidebar = ({ campaignReports }: { campaignReports: CampaignReport[] }) => {
    return (
        <>
            {campaignReports.map((x) => (
                <XSidebarListItem
                    key={x.campaignInstanceId}
                    title={format(new Date(x.publishedDate), DATE_DISPLAY_FORMAT)}
                    to={`${x.campaignInstanceId}`}
                />
            ))}
        </>
    );
};

export default Sidebar;
