import { Campaign, LanguageMappingsEn } from 'src/types';
import { TableCell, TableRow } from '@mui/material';
import { XIconButton, useAlignment } from 'src/components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Status from './Status';
import Actions from './Actions';
import Report from './Report';

const Item = ({ campaign }: { campaign: Campaign }) => {
    const align = useAlignment();
    return (
        <TableRow hover key={2}>
            <TableCell align={align('left')} padding="normal">
                {campaign.name}
            </TableCell>
            <TableCell align={align('left')} padding="normal">
                {campaign.templateName}
            </TableCell>
            <TableCell align={align('left')}>{LanguageMappingsEn[campaign.languageCode]}</TableCell>
            <TableCell align="center" padding="normal">
                <Status campaign={campaign} />
            </TableCell>
            <TableCell align="center" padding="normal">
                <Report campaign={campaign} />
            </TableCell>
            <TableCell align="center" padding="normal">
                <XIconButton tooltip="View campaign" to={`/campaigns/${campaign.id}`}>
                    <VisibilityIcon />
                </XIconButton>
            </TableCell>
            <TableCell align={align('right')}>
                <Actions campaign={campaign}></Actions>
            </TableCell>
        </TableRow>
    );
};
export default Item;
