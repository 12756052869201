import { FC, useState, createContext } from 'react';

type DrawerType = undefined | 'Drawer1' | 'Drawer2' | 'Drawer3';

type DrawerContext = {
    currentDrawer: DrawerType;
    openDrawer: (drawerType: DrawerType) => void;
    closeDrawer: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DrawerContext = createContext<DrawerContext>({} as DrawerContext);

export const DrawerProvider: FC = ({ children }) => {
    const [currentDrawer, setOpenDrawer] = useState<DrawerType>(undefined);

    const openDrawer = (drawerType: DrawerType) => {
        setOpenDrawer(drawerType);
    };
    const closeDrawer = () => {
        setOpenDrawer(undefined);
    };

    return (
        <DrawerContext.Provider value={{ currentDrawer, openDrawer, closeDrawer }}>{children}</DrawerContext.Provider>
    );
};
